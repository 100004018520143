<template>
    <div>
        <span v-for="(tag, index) in columnData" :key="tag.id">
            <span :id="'tag-' + rowId + '-' + tag.id">
                {{tag.name}}<template v-if="index < columnData.length - 1">, </template>
            </span>

            <b-tooltip :target="'tag-' + rowId + '-' + tag.id" placement="right" variant="light" :title="tag.description"></b-tooltip>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        rowId: Number,
        columnData: Array,
    }
}
</script>

<style scoped>

</style>
